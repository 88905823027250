import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { ROLE } from './utils';
const addModalReducer = (state = { label: '', isOpen: false }, action) => {
  switch (action.type) {
    case 'OPEN_EMPLOYEE_MODAL':
      return { label: 'Employee', isOpen: true };
    case 'CLOSE_EMPLOYEE_MODEL':
      return { label: '', isOpen: false };
    case 'OPEN_CLIENT_MODEL':
      return { label: 'Client', isOpen: true };
    case 'CLOSE_CLIENT_MODEL':
      return { label: '', isOpen: false };
    case 'OPEN_PROJECTMANAGER_MODEL':
      return { label: 'Project Manager', isOpen: true };
    case 'CLOSE_PROJECTMANAGER_MODEL':
      return { label: '', isOpen: false };
    case 'OPEN_PROJECT_MODEL':
      return { label: 'Project', isOpen: true };
    case 'CLOSE_PROJECT_MODEL':
      return { label: '', isOpen: false };
    default:
      return state;
  }
};

const modalReducer = (
  state = {
    isEmployeeOpen: false,
    isProjectOpen: false,
    isClientOpen: false,
    isProjectManagerOpen: false,
    isOpen: false,
    isEditFlow: false,
    projectName: '',
    projectId: '',
    projectSlug: '',
  },
  action
) => {
  switch (action.type) {
    case 'OPEN_ADD_NEW_PROJECT_MODAL':
      return {
        ...state,
        isProjectOpen: true,
        isEditFlow: action.payload ? action.payload.isEditFlow : false,
        projectName: action.payload ? action.payload.projectName : '',
        projectId: action.payload ? action.payload.projectId : '',
        projectSlug: action.payload ? action.payload.projectSlug : '',
      };
    case 'CLOSE_ADD_NEW_PROJECT_MODAL':
      return {
        ...state,
        isProjectOpen: false,
        isEditFlow: false,
        projectName: '',
        projectId: '',
        projectSlug: '',
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        [action.modalType]: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        [action.modalType]: false,
      };
    case 'OPEN_ADD_PROJECTMANAGER_MODAL':
      return {
        ...state,
        isProjectManagerOpen: true,
      };
    case 'CLOSE_ADD_PROJECTMANAGER__MODAL':
      return {
        ...state,
        isProjectManagerOpen: false,
      };
    case 'OPEN_ADD_EMPLOYEE_MODAL':
      return {
        ...state,
        isEmployeeOpen: true,
      };
    case 'CLOSE_ADD_EMPLOYEE_MODAL':
      return {
        ...state,
        isEmployeeOpen: false,
      };

    case 'OPEN_ADD_CLIENT_MODAL':
      return {
        ...state,
        isClientOpen: true,
      };
    case 'CLOSE_ADD_CLIENT_MODAL':
      return {
        ...state,
        isClientOpen: false,
      };
    default:
      return state;
  }
};

const boardReducer = (state = { lists: [] }, action) => {
  switch (action.type) {
    case 'ADD_LIST': {
      const { listId } = action.payload;
      return { lists: [...state.lists, listId] };
    }
    case 'MOVE_LIST': {
      const { oldListIndex, newListIndex } = action.payload;
      const newLists = Array.from(state.lists);
      const [removedList] = newLists.splice(oldListIndex, 1);
      newLists.splice(newListIndex, 0, removedList);
      return { lists: newLists };
    }
    default:
      return state;
  }
};

const listReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_TO_LIST': {
      const { listId, listTitle } = action.payload;
      return {
        ...state,
        [listId]: { _id: listId, title: listTitle, cards: [] },
      };
    }
    case 'CHANGE_LIST_TITLE': {
      const { listId, listTitle } = action.payload;
      return {
        ...state,
        [listId]: { ...state[listId], title: listTitle },
      };
    }
    case 'ADD_CARD': {
      const { listId, cardId } = action.payload;
      return {
        ...state,
        [listId]: { ...state[listId], cards: [...state[cardId]] },
      };
    }
    case 'MOVE_CARD': {
      const { oldCardIndex, newCardIndex, sourceListId, destListId } = action.payload;
      //Move within the same list
      if (sourceListId === destListId) {
        const newCards = Array.from(state[sourceListId].cards);
        const [removedCard] = newCards.splice(oldCardIndex, 1);
        newCards.splice(newCardIndex, 0, removedCard);
        return {
          ...state,
          [sourceListId]: { ...state[sourceListId], cards: newCards },
        };
      }

      //Move card from one list to another
      const sourceCards = Array.from(state[sourceListId].cards);
      const [removedCard] = sourceCards.splice(oldCardIndex, 1);
      const destinationCards = Array.from(state[destListId].cards);
      destinationCards.splice(newCardIndex, 0, removedCard);

      return {
        ...state,
        [sourceListId]: { ...state[sourceListId], cards: sourceCards },
        [destListId]: { ...state[destListId], cards: destinationCards },
      };
    }
    default:
      return state;
  }
};

const listOrderReducer = (
  state = {
    listData: [
      { title: 'Pending', bgColor: 'c-pending' },
      { title: 'In Progress', bgColor: 'c-inProgress' },
      { title: 'Done', bgColor: 'c-done' },
    ],
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_LIST_ORDER':
      return {
        ...state,
        listData: action.payload,
      };
    case 'GET_LIST_ORDER':
      return {
        ...state,
      };
    default:
      return state;
  }
};

const cardReducer = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_CARD_TO_LIST': {
      const { cardText, cardId } = action.payload;
      return {
        ...state,
        [cardId]: { ...state[cardId], text: cardText, _id: cardId },
      };
    }
    case 'CHANGE_CARD_TEXT': {
      const { cardText, cardId } = action.payload;
      return { ...state, [cardId]: { ...state[cardId], text: cardText } };
    }
    default:
      return state;
  }
};

// const employeeReducer = (
//   state = {
//     list: [
//       { name: "Chris Paul", _id: 646655 },
//       { name: "John Doe", _id: 645456655 },
//     ],
//   },
//   action
// ) => {
//   switch (action.type) {
//     case "EMPLOYEE_ADD_SUCCESS":
//       let temp = [...state.list];
//       temp.push(action.payload);
//       return {
//         ...state,
//         list: temp,
//       };
//     case "EMPLOYEE_LIST_SUCCESS":
//       return {
//         ...state,
//         list: action.payload.list,
//       };
//     default:
//       return state;
//   }
// };

// const newListReducer = (
//   state = {
//     title: [],
//     isNewListCreated: false,
//   },
//   action
// ) => {
//   switch (action.type) {
//     case "ADD_NEW_LIST":
//       return {
//         ...state,
//         title: action.payload,
//         isNewListCreated: true,
//       };
//     default:
//       return state;
//   }
// };

const errorReducer = (state = { authError: undefined, employeeError: undefined }, action) => {
  /** UNIVERSAL ERROR HANDLER */
  switch (action.type) {
    case 'SET_ERROR':
      let temp = {};
      if (action.payload?.status?.length !== undefined)
        action.payload?.status?.forEach((ele) => {
          if (ele.param === '_error')
            ele.nestedErrors.map((ele1) => {
              let a = ele1.param.split('.');
              if (a.length === 1) temp[a[0]] = ele1?.msg;
              else temp[a[a.length - 1]] = ele1?.msg;
            });
          else if (ele.param.includes('updates.')) temp[ele.param.split('.').slice(1).join('.')] = ele?.msg;
          else temp[ele.param] = ele?.msg;
        });

      return Object.assign({}, state, {
        [`${action.payload.scope}Error`]: temp,
      });
    default:
      return state;
  }
};

const loadingReducer = (
  state = {
    authLoading: false,
    addEmployeeLoading: false,
    addEntityLoading: false,
    addProjectLoading: false,
    loginLoading: false,
    fetchTasksLoading: false,
    fetchTaskForUpdateLoading: false,
    addTaskLoading: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_LOADING':
      return Object.assign({}, state, {
        [`${action.payload.scope}Loading`]: action.payload.status,
      });
    default:
      return state;
  }
};

const authReducer = (
  state = {
    isLoggedIn: -1,
    role: -1,
    isAuthenticated: -1,
    user: undefined,
    redirectURL: '',
  },
  action
) => {
  switch (action.type) {
    case 'AUTH_LOAD_SUCCESS':
      return {
        ...state,
        isAuthenticated: action?.payload?.isAuthenticated,
        role: action?.payload?.role,
        isLoggedIn: true,
        user: action?.payload,
      };

    case 'AUTH_LOAD_FAILURE':
      return {
        ...state,
        isAuthenticated: false,
        isLoggedIn: false,
        role: -1,
      };

    case 'AUTH_LOGIN_SUCCESS':
      return {
        ...state,
        role: action.payload.role,
        isLoggedIn: true,
      };

    case 'AUTH_LOGOUT_SUCCESS':
      return {
        isAuthenticated: false,
        isLoggedIn: false,
        user: undefined,
        role: -1,
      };

    case 'LOGIN_SUCCESS':
      return { ...state, isLoggedIn: true, user: action.payload };

    case 'STATIC_LOGIN_LIST_SUCCESS':
      return {
        ...state,
        loginList: action.payload,
      };
    case 'LOGOUT_SUCCESS':
      return {
        isLoggedIn: false,
        role: -1,
        isAuthenticated: -1,
      };
    case 'SET_REDIRECT_URL':
      return { ...state, redirectURL: action.payload };

    default:
      return state;
  }
};
const msgReducer = (state = { success: undefined, error: undefined }, action) => {
  switch (action.type) {
    case 'SET_TOAST':
      return Object.assign({}, state, {
        [`${action.payload.scope}`]: action.payload.status || 'Something went wrong!',
      });
    case 'CLEAR_TOAST':
      return { ...state, error: undefined, success: undefined };
    default:
      return state;
  }
};

const projectsReducer = (
  state = {
    projects: [],
  },
  action
) => {
  switch (action.type) {
    case 'ADD_PROJECTS_SUCCESS':
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };
    case 'FETCH_ALL_PROJECTS_SUCCESS':
      return {
        ...state,
        projects: action.payload,
      };
    default:
      return state;
  }
};

const clientsReducer = (
  state = {
    clients: [],
  },
  action
) => {
  switch (action.type) {
    case 'ADD_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: [...state.clients, action.payload],
      };
    case 'FETCH_ALL_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: action.payload,
      };
    default:
      return state;
  }
};

const projectManagersReducer = (
  state = {
    projectManagers: [],
  },
  action
) => {
  switch (action.type) {
    case 'ADD_PROJECTMANAGER_SUCCESS':
      return {
        ...state,
        projectManagers: [...state.projectManagers, action.payload],
      };
    case 'FETCH_ALL_PROJECTMANAGER_SUCCESS':
      return {
        ...state,
        projectManagers: action.payload,
      };
    default:
      return state;
  }
};

const employeesReducer = (
  state = {
    employees: [],
  },
  action
) => {
  switch (action.type) {
    case 'ADD_EMPLOYEES_SUCCESS':
      return {
        ...state,
        employees: [...state.employees, action.payload],
      };
    case 'FETCH_ALL_EMPLOYEES_SUCCESS':
      return {
        ...state,
        employees: action.payload,
      };
    default:
      return state;
  }
};

const dashboardReducer = (
  state = {
    employees: [],
    clients: [],
    projectManagers: [],
    projects: [],
  },
  action
) => {
  switch (action.type) {
    case 'ADD_PROJECT':
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };
    case 'ADD_EMPLOYEES_SUCCESS':
      return {
        ...state,
        employees: [action.payload, ...state.employees],
      };
    case 'FETCH_ALL_EMPLOYEES_SUCCESS':
      return {
        ...state,
        employees: action.payload,
      };
    case 'DELETE_MEMBER':
      const type = action.payload.type;
      switch (type) {
        case ROLE.EMPLOYEE:
          return {
            ...state,
            employees: state.employees.filter((member) => member._id !== action.payload.memberId),
          };
        case ROLE.PROJECTMANAGER:
          return {
            ...state,
            projectManagers: state.projectManagers.filter((member) => member._id !== action.payload.memberId),
          };
        case ROLE.CLIENT:
          return {
            ...state,
            clients: state.clients.filter((member) => member._id !== action.payload.memberId),
          };
        default:
          return {
            ...state,
          };
      }

    case 'DELETE_PROJECT':
      return {
        ...state,
        projects: state.projects.filter((project) => project._id !== action.payload),
      };
    case 'ADD_PROJECTMANAGER_SUCCESS':
      return {
        ...state,
        projectManagers: [action.payload, ...state.projectManagers],
      };
    case 'FETCH_ALL_PROJECTMANAGER_SUCCESS':
      return {
        ...state,
        projectManagers: action.payload,
      };
    case 'ADD_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: [action.payload, ...state.clients],
      };
    case 'FETCH_ALL_CLIENTS_SUCCESS':
      return {
        ...state,
        clients: action.payload,
      };
    case 'ADD_PROJECTS_SUCCESS':
      return {
        ...state,
        projects: [action.payload, ...state.projects],
      };
    case 'FETCH_ALL_PROJECTS_SUCCESS':
      return {
        ...state,
        projects: action.payload,
      };
    case 'UPDATE_PROJECT_TITLE':
      const { id, newTitle, newSlug } = action.payload;
      // const projectIndex = state.projects.findIndex(
      //   (project) => project._id === id
      // );
      // const projectToUpdate = state.projects[projectIndex];
      // const updatedProject = { ...projectToUpdate, title: newTitle };
      const updatedProjects = state.projects.map((project) => {
        if (project._id === id) {
          return { ...project, title: newTitle, slug: newSlug };
        }
        return project;
      });

      return { ...state, projects: updatedProjects };
    default:
      return state;
  }
};

const addTaskReducer = (
  state = {
    isEditFlow: false,
    isStepActive: false,
    currentStep: 0,
    editStep: false,
    listId: 0,
    members: [],
    lists: [],
    projectDetails: {},
    status: [],
    selectedStatus: [],
    task: {},
    taskId: '',
    selectedMembers: [],
    checkLists: [],
    attachments: [],
    isUpdated: false,
    isTaskAdded: false,
    isChecklistAdded: false,
    access: '',
    comments: [],
  },
  action
) => {
  switch (action.type) {
    case 'CHANGE_STEP':
      return {
        ...state,
        currentStep: action.payload,
      };
    case 'CHANGE_STEP_PAYLOAD':
      if (action.payload.step === 1) {
        return {
          ...state,
          currentStep: action.payload.step,
          listId: action.payload.listId,
          projectDetails: { ...action.payload.projectDetails },
        };
      }
      if (action.payload.step === 2) {
        return {
          ...state,
          currentStep: action.payload.step,
          listId: '',
          projectDetails: { ...action.payload.projectDetails },
          taskId: action.payload.taskId,
          isEditFlow: action.payload.isEditFlow,
        };
      }
      return state;

    case 'REMOVE_STATUS':
      return {
        ...state,
        status: state.status.filter((status) => status._id !== action.payload.statusId),
      };

    case 'SET_PROJECT_DETAILS_FOR_ADD_TASK':
      return {
        ...state,
        projectDetails: { ...action.payload },
      };
    case 'SET_UPDATE_FLAG':
      return {
        ...state,
        isUpdated: action.payload,
      };
    case 'SET_TASK_ADD_FLAG':
      return {
        ...state,
        isTaskAdded: action.payload,
      };
    case 'SET_CHECKLIST_ADD_FLAG':
      return {
        ...state,
        isChecklistAdded: action.payload,
      };
    case 'UPDATE_COMMENTS':
      return {
        ...state,
        comments: [...action.payload],
      };
    case 'ADD_CHECKLIST_DATA':
      return {
        ...state,
        checkLists: [...action.payload],
      };
    case 'ADD_CHECKLIST_ITEM_DATA':
      const { checkListId, checkListItems } = action.payload;

      const checkListIndex = state.checkLists.findIndex((checkList) => checkList._id === checkListId);

      if (checkListIndex !== -1) {
        const updatedCheckLists = state.checkLists.map((checkList, index) => {
          if (index === checkListIndex) {
            return {
              ...checkList,
              items: [...checkList.items, ...checkListItems],
            };
          }
          return checkList;
        });

        return {
          ...state,
          checkLists: updatedCheckLists,
        };
      } else {
        return state;
      }

    case 'TOGGLE_CHECKLIST_ITEM':
      const { itemId } = action.payload;

      const updatedCheckLists = state.checkLists.map((checkList) => {
        const itemIndex = checkList.items.findIndex((item) => item._id === itemId);

        if (itemIndex !== -1) {
          const updatedItems = [...checkList.items];
          updatedItems[itemIndex] = {
            ...updatedItems[itemIndex],
            isChecked: !updatedItems[itemIndex].isChecked,
          };

          return {
            ...checkList,
            items: updatedItems,
          };
        }

        return checkList;
      });

      return {
        ...state,
        checkLists: updatedCheckLists,
      };

    case 'UPLOAD_ATTACHMENT_SUCCESS':
      return {
        ...state,
        attachments: [...state.attachments, ...action.payload],
      };

    case 'DELETE_ATTACHMENT_SUCCESS':
      return {
        ...state,
        attachments: [...state.attachments.filter((attachment) => attachment !== action.payload)],
      };

    case 'FETCH_PROJECT_ALL_STATUS_FOR_ADD_TASK_SUCCESS':
      return {
        ...state,
        status: [...action.payload.statuses],
      };
    case 'FETCH_TASK_DETAILS_FOR_TASK_DETAILS_MODAL':
      return {
        ...state,
        task: { ...action.payload },
        selectedStatus: [...action.payload.status],
        selectedMembers: [...(action.payload.assignee || [])],
        checkLists: [...action.payload.checkLists],
        attachments: [...action.payload.attachments.map((attachment) => attachment.filename)],
        access: action.payload.project.projectAccess ?? 'edit',
        comments: action.payload.comments,
      };
    case 'SET_SELECTED_STATUS_FOR_TASK_UPDATE':
      return {
        ...state,
        selectedStatus: [...action.payload],
      };
    case 'SET_SELECTED_MEMBERS_FOR_TASK_UPDATE':
      return {
        ...state,
        selectedMembers: [...action.payload],
      };
    case 'REORDER_CHECKLIST_ITEMS':
      const { mainChecklistId, startIndex, endIndex } = action.payload;
      const checklistIndex = state.checkLists.findIndex((c) => c._id === mainChecklistId);

      if (checklistIndex === -1) {
        // Checklist not found, return current state
        return state;
      }

      const updatedChecklists = [...state.checkLists];
      const checklistToUpdate = { ...updatedChecklists[checklistIndex] };
      const updatedItems = [...checklistToUpdate.items];
      const [removed] = updatedItems.splice(startIndex, 1);
      updatedItems.splice(endIndex, 0, removed);

      checklistToUpdate.items = updatedItems;
      updatedChecklists[checklistIndex] = checklistToUpdate;

      return {
        ...state,
        checkLists: updatedChecklists,
      };

    case 'MOVE_CHECKLIST_ITEM':
      const { sourceChecklistId, destinationChecklistId, checklistItemId, destinationIndex } = action.payload;

      const sourceChecklistIndex = state.checkLists.findIndex((c) => c._id === sourceChecklistId);
      const destinationChecklistIndex = state.checkLists.findIndex((c) => c._id === destinationChecklistId);

      if (sourceChecklistIndex === -1 || destinationChecklistIndex === -1) {
        return state;
      }

      const checklistsUpdated = [...state.checkLists];

      const sourceChecklist = checklistsUpdated[sourceChecklistIndex];
      const destinationChecklist = checklistsUpdated[destinationChecklistIndex];
      const itemToMove = sourceChecklist.items.find((item) => item._id === checklistItemId);

      if (!itemToMove) {
        return state;
      }

      const updatedSourceItems = sourceChecklist.items.filter((item) => item._id !== checklistItemId);
      checklistsUpdated[sourceChecklistIndex] = {
        ...sourceChecklist,
        items: updatedSourceItems,
      };

      const updatedDestinationItems = [...destinationChecklist.items];
      updatedDestinationItems.splice(destinationIndex, 0, itemToMove);
      checklistsUpdated[destinationChecklistIndex] = {
        ...destinationChecklist,
        items: updatedDestinationItems,
      };

      return {
        ...state,
        checkLists: checklistsUpdated,
      };

    case 'DELETE_CHECKLIST':
      const checklistId = action.payload;
      const checklistsToUpdate = [...state.checkLists];
      const newChecklists = checklistsToUpdate.filter((checklist) => checklist._id !== checklistId);
      return {
        ...state,
        checkLists: [...newChecklists],
      };
    case 'DELETE_CHECKLIST_ITEM':
      const { mainCheckListId, childItemId } = action.payload;

      // Find the checklist
      const mainChecklistIndex = state.checkLists.findIndex((c) => c._id === mainCheckListId);

      // Ensure the checklist exists
      if (mainChecklistIndex === -1) {
        return state; // Checklist not found, return current state
      }

      const updatedCheckListsData = [...state.checkLists];
      const checkListToUpdate = {
        ...updatedCheckListsData[mainChecklistIndex],
      };

      // Filter out the item to delete
      checkListToUpdate.items = checkListToUpdate.items.filter((item) => item._id !== childItemId);
      updatedCheckListsData[mainChecklistIndex] = checkListToUpdate;

      return {
        ...state,
        checkLists: updatedCheckListsData,
      };

    case 'SANITIZE_ADD_TASK_STORE':
      return {
        ...state,
        isEditFlow: false,
        listId: 0,
        // members: [],
        lists: [],
        projectDetails: {},
        // status: [],
        selectedStatus: [],
        task: {},
        taskId: '',
        selectedMembers: [],
        checkLists: [],
        attachments: [],
        comments: [],
      };
    case 'IS_STEP_ACTIVE':
      return {
        ...state,
        isStepActive: action.payload.isStepActive,
        listId: action.payload.listId,
      };

    case 'IS_ADD_TASK_STEP_ACTIVE':
      return {
        ...state,
        isStepActive: action.payload,
      };
    case 'EDIT_STEP':
      return {
        ...state,
        editStep: action.payload,
      };
    case 'FETCH_LISTS_SUCCESS_FOR_ADD_TASK':
      return {
        ...state,
        lists: [...action.payload.lists],
      };
    case 'FETCH_MEMBERS_SUCCESS':
      return {
        ...state,
        members: [...action.payload],
      };
    default:
      // console.log("Unknown action type:", action.type);
      return state;
  }
};

const statusReducer = (
  state = {
    status: [],
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_ALL_STATUS_SUCCESS':
      return {
        ...state,
        status: [...action.payload],
      };

    default:
      return state;
  }
};
const adminDashboardTasksDataReducer = (
  state = {
    data: {},
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_ADMIN_DASHBOARD_DATA_SUCCESS':
      return {
        ...state,
        data: { ...action.payload },
      };

    default:
      return state;
  }
};

//To show task on user screen(list view)
const taskDataReducer = (
  state = {
    taskData: [],
    lists: [],
    access: '',
    isNewListCreated: false, //To close add list section appearing beside lists
  },
  action
) => {
  switch (action.type) {
    case 'ADD_LIST_WITH_TASK':
      return {
        ...state,
        lists: [action.payload.listDataAdd],
        taskData: [action.payload.listDataWithTaskNew],
      };
    case 'UPDATE_TASKS_LISTVIEW':
      return {
        ...state,
        taskData: action.payload,
      };

    case 'REPLACE_UPDATED_TASK_LIST':
      const { listID, taskID, taskDataObject } = action.payload;

      const updatedListsData = state.taskData.map((list) => {
        if (list._id === listID) {
          const updatedTasks = list.tasks.map((task) => {
            if (task._id === taskID) {
              return { ...task, ...taskDataObject };
            }
            return task;
          });

          return { ...list, tasks: updatedTasks };
        }
        return list;
      });

      return {
        ...state,
        taskData: updatedListsData,
      };

    case 'APPEND_TASKS_LISTVIEW':
      const { taskData, listIdOfNewTask } = action.payload;

      const updatedLists = state.taskData.map((list) => {
        if (list._id === listIdOfNewTask) {
          return {
            ...list,
            tasks: [...list.tasks, taskData],
          };
        }
        return list;
      });

      return {
        ...state,
        taskData: updatedLists,
      };
    case 'CLEAR_TASK_DATA':
      return {
        ...state,
        taskData: [],
        lists: [],
      };
    case 'FETCH_PROJECT_LISTS_SUCCESS':
      return {
        ...state,
        // taskData: [...action.payload.data],
        lists: [
          ...action.payload.lists.map((list) => {
            return {
              _id: list._id,
              title: list.title,
              displayOrder: list.displayOrder,
            };
          }),
        ],
        access: action.payload.projectAccess ?? 'edit',
      };
    case 'FETCH_LIST_TASKS_SUCCESS':
      return {
        ...state,
        taskData: state.taskData.some((list) => list._id === action.payload._id)
          ? state.taskData.map((list) => (list._id === action.payload._id ? action.payload : list))
          : [...state.taskData, action.payload],
      };
    case 'FETCH_LISTS_SUCCESS':
      return {
        ...state,
        lists: [...action.payload],
      };
    case 'ADD_NEW_LIST':
      return {
        ...state,
        lists: [{ ...action.payload }, ...state.lists],
        isNewListCreated: true,
      };

    case 'MOVE_TASK_LIST':
      // Destructuring payload
      const { taskId, sourceListId, destinationListId, destinationIndex } = action.payload;

      // Find the source list and task
      const sourceListIndex = state.taskData.findIndex((list) => list._id === sourceListId);
      const sourceList = state.taskData[sourceListIndex];
      const taskIndex = sourceList.tasks.findIndex((task) => task._id === taskId);
      const task = sourceList.tasks[taskIndex];

      // Remove task from source list
      const updatedSourceTasks = [...sourceList.tasks.slice(0, taskIndex), ...sourceList.tasks.slice(taskIndex + 1)];

      // Find the destination list
      const destinationListIndex = state.taskData.findIndex((list) => list._id === destinationListId);
      const destinationList = state.taskData[destinationListIndex];

      // Update task's list field and listDetails
      const updatedTask = {
        ...task,
        list: destinationList._id,
        listDetails: {
          _id: destinationList._id,
          title: destinationList.title,
          displayOrder: destinationList.displayOrder,
        },
      };

      // Add updatedTask to destination list at specified index
      const updatedDestinationTasks = [
        ...destinationList.tasks.slice(0, destinationIndex),
        updatedTask,
        ...destinationList.tasks.slice(destinationIndex),
      ];

      // Update the state with the changes
      return {
        ...state,
        taskData: state.taskData.map((list) => {
          if (list._id === sourceListId) {
            return { ...list, tasks: updatedSourceTasks };
          }
          if (list._id === destinationListId) {
            return { ...list, tasks: updatedDestinationTasks };
          }
          return list;
        }),
      };
    // case "ADD_NEW_TASK":
    //   let { list: newListId, ...newTask } = { ...action.payload };

    //   // Make a copy of the taskData object
    //   let updatedTaskData = { ...state.taskData };

    //   // Find the list in the taskData object
    //   let listIndex = updatedTaskData.data.findIndex(
    //     (list) => list._id === newListId
    //   );

    //   if (listIndex !== -1) {
    //     // Make a copy of the list that is being updated
    //     const listToUpdate = { ...updatedTaskData.data[listIndex] };

    //     // Make a copy of the tasks array and push the new task
    //     const updatedTasks = [...listToUpdate.tasks, { ...newTask }];

    //     // Update the list with the new tasks array
    //     listToUpdate.tasks = updatedTasks;

    //     // Update the taskData object with the updated list
    //     // updatedTaskData.data[listIndex] = listToUpdate;
    //     const updatedData = [...updatedTaskData.data];
    //     updatedData[listIndex] = listToUpdate;

    //     // Update the state with the new taskData object
    //     updatedTaskData = { ...updatedTaskData, data: updatedData };
    //   }

    //   console.log("Store New Data", updatedTaskData);

    //   return {
    //     ...state,
    //     taskData: { ...updatedTaskData },
    //   };

    case 'REMOVE_LIST':
      const { listId } = action.payload;

      return {
        ...state,
        lists: state.lists.filter((list) => list._id !== listId),
        taskData: state.taskData.filter((list) => list._id !== listId),
      };
    default:
      return state;
  }
};

const taskDataStatusReducer = (
  state = {
    statuses: [],
    taskData: [],
    access: '',
  },
  action
) => {
  switch (action.type) {
    case 'REMOVE_TASK_FROM_STATUS_FOR_UPDATE':
      const { statusIdToRemove, taskIdToRemove } = action.payload;
      const newState = state.taskData.map((status) => {
        if (status._id === statusIdToRemove) {
          return {
            ...status,
            tasks: status.tasks.filter((task) => task._id !== taskIdToRemove),
          };
        }
        return status;
      });
      return { ...state, taskData: newState };
    case 'ADD_TASK_INTO_STATUS_FOR_UPDATE':
      const { statusId, taskData } = action.payload;

      const updatedState = state.taskData.map((status) => {
        if (status._id === statusId) {
          return {
            ...status,
            tasks: [...status.tasks, taskData],
          };
        }
        return status;
      });

      return { ...state, taskData: updatedState };
    case 'UPDATE_TASKS_STATUSVIEW':
      return {
        ...state,
        taskData: action.payload,
      };
    case 'REPLACE_UPDATED_TASK_STATUS':
      const { statusIds, taskId, taskDataObject } = action.payload;
      const updatedStatuses = state.taskData.map((status) => {
        if (statusIds.includes(status._id)) {
          const updatedTasks = status.tasks.map((task) => {
            if (task._id === taskId) {
              return { ...task, ...taskDataObject };
            }
            return task;
          });

          return { ...status, tasks: updatedTasks };
        }
        return status;
      });

      return {
        ...state,
        taskData: updatedStatuses,
      };
    case 'FETCH_STATUS_TASKS_SUCCESS':
      return {
        ...state,
        taskData: state.taskData.some((status) => status._id === action.payload._id)
          ? state.taskData.map((status) => (status._id === action.payload._id ? action.payload : status))
          : [...state.taskData, action.payload],
      };
    case 'FETCH_TASKS_FOR_STATUS_VIEW':
      console.log('Payload: ', action.payload);
      return {
        ...state,
        // taskData: [...action.payload.data],
        statuses: [
          ...action.payload.data.statuses.map((status) => {
            return {
              _id: status._id,
              title: status.title,
              color: status.color,
              // displayOrder: status.displayOrder,
            };
          }),
        ],
        access: action.payload.data.projectAccess ?? 'edit',
      };
    case 'MOVE_TASK_STATUS': {
      const { taskId, sourceStatusId, destinationStatusId, sourceIndex, destinationIndex } = action.payload;

      // Find the source status and task to move
      const sourceStatusIndex = state.taskData.findIndex((status) => status._id === sourceStatusId);
      const sourceStatus = state.taskData[sourceStatusIndex];
      const taskToMove = sourceStatus.tasks.find((task) => task._id === taskId);
      if (!taskToMove) {
        console.log('Cannot find task');
        return state;
      }

      // Find the destination status
      const destinationStatusIndex = state.taskData.findIndex((status) => status._id === destinationStatusId);
      const destinationStatus = state.taskData[destinationStatusIndex];

      // Update statusDetails array in task
      const statusDetailsToUpdate = [...taskToMove.statusDetails];
      const updatedStatusDetails = statusDetailsToUpdate
        .filter((status) => status._id !== sourceStatusId)
        .concat({
          _id: destinationStatus._id,
          title: destinationStatus.status,
          displayOrder: destinationStatus.displayOrder,
          color: destinationStatus.color,
        })
        .sort((a, b) => a.displayOrder - b.displayOrder);

      // Update the statusInfo object of the taskToMove
      const updatedTaskToMove = {
        ...taskToMove,
        statusInfo: {
          _id: destinationStatus._id,
          title: destinationStatus.status,
          color: destinationStatus.color,
          displayOrder: destinationStatus.displayOrder,
        },
        statusDetails: updatedStatusDetails,
      };

      // Remove the task from the source status
      const updatedSourceTasks = [
        ...sourceStatus.tasks.slice(0, sourceIndex),
        ...sourceStatus.tasks.slice(sourceIndex + 1),
      ];

      // Add the task to the destination status
      const updatedDestinationTasks = [
        ...destinationStatus.tasks.slice(0, destinationIndex),
        updatedTaskToMove,
        ...destinationStatus.tasks.slice(destinationIndex),
      ];

      // Create updated status objects
      const updatedSourceStatus = {
        ...sourceStatus,
        tasks: updatedSourceTasks,
      };
      const updatedDestinationStatus = {
        ...destinationStatus,
        tasks: updatedDestinationTasks,
      };

      // Update the taskData array with the modified statuses
      const updatedTaskData = state.taskData.map((status) => {
        if (status._id === sourceStatusId) {
          return updatedSourceStatus;
        } else if (status._id === destinationStatusId) {
          return updatedDestinationStatus;
        } else {
          const taskIndex = status.tasks.findIndex((task) => task._id === taskId);
          if (taskIndex !== -1) {
            const updatedTasks = status.tasks.map((task, index) =>
              index === taskIndex ? { ...task, statusDetails: updatedStatusDetails } : task
            );
            return { ...status, tasks: updatedTasks };
          } else {
            return status;
          }
        }
      });

      return {
        ...state,
        taskData: updatedTaskData,
      };
    }

    case 'REMOVE_TASK_FROM_STATUS': {
      const { taskId, sourceStatusId } = action.payload;
      const sourceStatusIndex = state.taskData.findIndex((status) => status._id === sourceStatusId);
      const sourceStatus = state.taskData[sourceStatusIndex];
      const updatedSourceStatusTasks = sourceStatus.tasks.filter((task) => task._id !== taskId);

      const updatedSourceStatus = {
        ...sourceStatus,
        tasks: [...updatedSourceStatusTasks],
      };

      const updatedTaskData = state.taskData.map((status, index) => {
        if (index === sourceStatusIndex) {
          return updatedSourceStatus;
        } else {
          const index = status.tasks.findIndex((task) => task._id === taskId);
          if (index !== -1) {
            const taskToUpdateInOtherStatus = status.tasks[index];
            const updatedStatusDetails = taskToUpdateInOtherStatus.statusDetails.filter(
              (status) => status._id !== sourceStatusId
            );
            const updatedTaskInOtherStatus = {
              ...taskToUpdateInOtherStatus,
              statusDetails: [...updatedStatusDetails],
            };

            const updatedStatusTasks = status.tasks.map((task, index) => {
              if (task._id === taskId) {
                return updatedTaskInOtherStatus;
              } else {
                return task;
              }
            });
            return {
              ...status,
              tasks: [...updatedStatusTasks],
            };
          } else {
            return status;
          }
        }
      });
      return { ...state, taskData: updatedTaskData };
    }
    default:
      return state;
  }
};

const searchReducer = (
  state = {
    searchTerm: '',
  },
  action
) => {
  switch (action.type) {
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'RESET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: '',
      };
    default:
      return state;
  }
};

const roleReducer = (
  state = {
    userRole: '',
  },
  action
) => {
  switch (action.type) {
    case 'SET_USER_ROLE':
      return {
        ...state,
        userRole: action.payload,
      };
    default:
      return state;
  }
};

const navigationReducer = (
  state = {
    type: '',
  },
  action
) => {
  switch (action.type) {
    case 'SET_LABEL':
      return {
        ...state,
        type: action.payload,
      };

    default:
      return state;
  }
};

const previewReducer = (
  state = {
    isVisible: false,
    fileExtension: '',
    fileUrl: '',
    index: 0,
  },
  action
) => {
  switch (action.type) {
    case 'OPEN_FILE_PREVIEW':
      return {
        ...state,
        isVisible: true,
        index: action.payload,
      };
    case 'CLOSE_FILE_PREVIEW':
      return {
        ...state,
        isVisible: false,
        index: 0,
      };
    default:
      return state;
  }
};

const viewTypeReducer = (
  state = {
    viewType: 'list', // Default view type is 'list'
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_VIEW_TYPE':
      return {
        ...state,
        viewType: action.payload,
      };
    case 'GET_VIEW_TYPE':
      return {
        ...state,
      };
    default:
      return state;
  }
};

const projectReducer = (
  state = {
    project: '',
  },
  action
) => {
  switch (action.type) {
    case 'UPDATE_PROJECT':
      return {
        ...state,
        project: action.payload,
      };
    default:
      return state;
  }
};

const stageReducer = (
  state = {
    task: '',
  },
  action
) => {
  switch (action.type) {
    case 'STAGE_TASK':
      return {
        ...state,
        task: action.payload,
      };
    case 'UNSTAGE_TASK':
      return {
        ...state,
        task: '',
      };
    default:
      return state;
  }
};

// const store = configureStore({
//   reducer: combineReducers({
//     modal: modalReducer,
//     board: boardReducer,
//     list: listReducer,
//     listOrder: listOrderReducer,
//     card: cardReducer,
//     // employee: employeeReducer,
//     error: errorReducer,
//     // newList: newListReducer,
//     msg: msgReducer,
//     loading: loadingReducer,
//     auth: authReducer,
//     // projects: projectsReducer,
//     // clients: clientsReducer,
//     // projectManagers: projectManagersReducer,
//     // employees: employeesReducer,
//     addModal: addModalReducer, //Used for adding entities
//     addTask: addTaskReducer, //Used for adding task
//     dashboard: dashboardReducer, //Used
//     status: statusReducer, //Used for storing app-wide status
//     adminDashboardData: adminDashboardTasksDataReducer, //Used for storing tasks for Admin Dashboard
//     taskData: taskDataReducer, //Used for storing tasks for Admin->Project View page (List)
//     taskDataByStatus: taskDataStatusReducer,
//     role: roleReducer,
//   }),
// });

const appReducer = combineReducers({
  modal: modalReducer,
  board: boardReducer,
  list: listReducer,
  listOrder: listOrderReducer,
  card: cardReducer,
  error: errorReducer,
  msg: msgReducer,
  loading: loadingReducer,
  auth: authReducer,
  addModal: addModalReducer,
  addTask: addTaskReducer,
  dashboard: dashboardReducer,
  status: statusReducer,
  adminDashboardData: adminDashboardTasksDataReducer,
  taskData: taskDataReducer,
  taskDataByStatus: taskDataStatusReducer,
  role: roleReducer,
  navigation: navigationReducer,
  preview: previewReducer,
  search: searchReducer,
  viewType: viewTypeReducer,
  project: projectReducer,
  stage: stageReducer,
});

// Create a root reducer that handles the reset action
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    state = undefined; // Reset the state to initial state
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
